@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);

  .app-icon {
    color: mat.get-color-from-palette($foreground, text);
  }

  .mat-button-disabled {

    .app-icon {
      color: rgba(mat.get-color-from-palette($foreground, text), 0.26) !important;
    }
  }
}

:host {
  display: flex;
  place-content: center space-around;
}

.mat-icon {
  vertical-align: middle;
  height: unset;
  width: unset;
  line-height: unset;
  overflow: visible;
}
