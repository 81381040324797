@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {

  $is-dark: map.get($theme, is-dark);
  $primary: map.get($theme, 'primary');
  $accent: map.get($theme, 'accent');

  .app-nav-card-inner {
    color: mat.get-color-from-palette($primary, main);
  }
}

