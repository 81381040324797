@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  .icon-text-button {
    background: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, text);
  }

  .icon-text-button.mat-accent {
    .app-icon {
      color: mat.get-color-from-palette($accent, 'main-contrast');
    }
  }

  .icon-text-button.mat-primary {
    .app-icon {
      color: mat.get-color-from-palette($primary, 'main-contrast');
    }
  }
  .icon-text-button.mat-warn {
    .app-icon {
      color: mat.get-color-from-palette($warn, 'main-contrast');
    }
  }
}
