@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme){
  $config: mat.get-color-config($theme);
  $is-dark: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $primary: map.get($theme, 'primary');
  $accent: map.get($theme, 'accent');

  $active: if($is-dark, mat.get-color-from-palette($primary, main), mat.get-color-from-palette($accent, main));

  .app-card-area {
    .app-card {
      background-color: #E9EEEF;
    }
  }

  .app-card {
    background-color: mat.get-color-from-palette($background, card);
    
    &.active {
      border: 2px solid $active;
    }

    .mat-tab-label, .mat-tab-link {
      background-color: mat.get-color-from-palette($background, background);
      color: mat.get-color-from-palette($foreground, text) !important;

      &.mat-tab-label-active, &.active-link {
        background-color: mat.get-color-from-palette($accent, lighter) !important;
        color: mat.get-color-from-palette($accent, 'lighter-contrast') !important;
      }
    }
  }

  .app-card-background {
    background-color: mat.get-color-from-palette($background, card);
  }
}

.app-card {
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 1rem;

  &.app-info-card {
    padding: 0.5rem 1rem;
  }
}
