@use 'sass:map';

@mixin theme($theme) {
  $is-dark: map.get($theme, is-dark);

  $color:  if($is-dark, white, #636363);
  .timeline-container {
    color: $color;
    fill: $color;

    .app-icon {
      color: $color;
    }
  }
}
