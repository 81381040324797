@use 'sass:map';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'colors' as colors;
@use 'theme' as app;

@import 'quill/dist/quill.snow.css';

$cardo: 'Cardo', serif;
$oswald: 'Oswald', sans-serif;
// Define the palettes and theme
$havtech-portal-primary: mat.define-palette(colors.$fjaere-palette, main, lighter, darker);
$havtech-portal-accent: mat.define-palette(colors.$havtal-gul-palette, main, lighter, darker);
$havtech-portal-warn: mat.define-palette(colors.$fender-palette, main, lighter, darker);
$havtech-portal-typography: mat.define-typography-config(
  $font-family:   $cardo,
  //// mat.define-typography-level(font-size, line-height, font-weight, font-family, letter-spacing)
  $display-4:     mat.define-typography-level(112px, 112px, 300, $oswald, $letter-spacing: -0.05em),
  $display-3:     mat.define-typography-level(56px, 56px, 400, $oswald, $letter-spacing: -0.02em),
  $display-2:     mat.define-typography-level(45px, 48px, 400, $oswald, $letter-spacing: -0.005em),
  $display-1:     mat.define-typography-level(36px, 53.35px, 400, $oswald),
  $headline:      mat.define-typography-level(28px, 41.5px, 300, $oswald),// h1
  $title:         mat.define-typography-level(26px, 38.53px, 400, $oswald),  // h2
  $subheading-2:  mat.define-typography-level(24px, 35.57px, 300, $oswald),  // h3
  $subheading-1:  mat.define-typography-level(18px, 26.68px, 300, $oswald),  // h4
  $body-2:        mat.define-typography-level(18px, 23.4px, 700, $cardo),
  $body-1:        mat.define-typography-level(18px, 23.4px, 400, $cardo),
  $caption:       mat.define-typography-level(12px, 15.6px, 400, $cardo),
  $button:        mat.define-typography-level(14px, 21px, 400, $oswald),
  $input:         mat.define-typography-level(inherit, 1.125, 400, $cardo)
);

$border-radius: 10px;

// Utility classes
.m-auto {
  margin: auto !important;
}

.m-left-10 {
  margin-left: 10px;
}

.m-left-30 {
  margin-left: 30px;
}

.m-right-10 {
  margin-right: 10px;
}

.m-right-30 {
  margin-right: 30px;
}

.m-right-50 {
  margin-right: 50px;
}

.m-right-minus-50 {
  margin-right: -50px;
}

.m-left-50 {
  margin-left: 50px;
}

.m-left-minus-50 {
  margin-left: -50px;
}

.m-top-5 {
  margin-top: 5px;
}

.m-top-minus-5 {
  margin-top: -5px;
}

.m-top-10 {
  margin-top: 10px;
}

.m-top-minus-10 {
  margin-top: -10px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-top-50 {
  margin-top: 50px;
}

.m-bottom-5 {
  margin-bottom: 5px;
}

.m-bottom-10 {
  margin-bottom: 10px;
}

.m-bottom-minus-10 {
  margin-bottom: -10px;
}

.m-bottom-30 {
  margin-bottom: 30px;
}

.p-right-5 {
  padding-right: 5px;
}

.p-top-20 {
  padding-top: 20px;
}

.width-percentage-100 {
  width: 100%;
}

.text-align-left {
	text-align: left;
}

.text-align-right {
	text-align: right;
}

.font-size-smaller {
	font-size: smaller;
}

.align-self-center {
	align-self: center;
}

.justify-center {
	justify-content: center;
}

.italic {
  font-style: italic;
}

// Small text often used in combination with controls with xs class
.text-sm {
  font: 400 12px / 16.25px $cardo !important;
}
.text-sm-2 {
  font: 700 12px / 16.25px $cardo !important;
}

.text-md {
  font: 400 14px / 18.96px $cardo !important;
}
.text-md-2 {
  font: 700 14px / 18.96px $cardo !important;
}

// Really small text!
.text-xs {
  font: 400 9px / 12.19px $cardo;
}

.text-xs-alt {
  font: 400 10px / 13.54px $cardo;
}

.text-lg {
  font: 400 22px / 29.79px $cardo !important;
}
.text-lg-2 {
  font: 700 22px / 29.79px $cardo !important;
}

.text-gt-lg {
  font: 400 24px / 1.3% $cardo !important;
}

.text-huge {
  font: 700 28px / 42px  $cardo !important;
}

.nav-link-1 {
  font: 300 16px / 42px $oswald !important;
}

.nav-link-2 {
  font: 400 18px / 42px $oswald !important;
}

.h7{
  font: 300 10px / 10px $oswald !important;
}

.weight-300 {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter;
}

.cursor-pointer {
  cursor: pointer;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($havtech-portal-typography);

@import 'mapbox-gl/dist/mapbox-gl.css';

$light-theme: mat.define-light-theme((
  color: (
    primary: $havtech-portal-primary,
    accent: $havtech-portal-accent,
    warn: $havtech-portal-warn,

  ),
  typography: $havtech-portal-typography
));

$light-theme: map.merge($light-theme, (
    foreground: colors.$light-theme-foreground,
    background: colors.$light-theme-background,
));

$dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette(colors.$havtal-dark-palette, main, lighter, darker),
    accent: $havtech-portal-accent,
    warn: $havtech-portal-warn,
  ),
  typography: $havtech-portal-typography
));

$dark-theme: map.merge($dark-theme, (
  foreground: colors.$dark-theme-foreground,
  background: colors.$dark-theme-background,
));

@include mat.all-component-themes($light-theme);
@include app.theme($light-theme);

.theme-dark {
  @include mat.all-component-colors($dark-theme);
  @include app.theme($dark-theme);
}

/* You can add global styles to this file, and also import other style files */

html, body, app-root {
  height: 100%;
  width: 100%;
  display: inline-block;
}
body { margin: 0 }

.mat-drawer-inner-container {
  overflow-x: hidden !important;
}

.mat-tab-body-content {
  overflow: unset !important;
}

.cdk-overlay-container > .cdk-global-overlay-wrapper {
  overflow: auto;
  pointer-events: auto;  // needed for chrome but prevents backdrop click close

  @media only screen and (min-width: 1239.99px) {
    min-width: 1080px;
  }
}

.outer-viewport {
  min-height: calc(100vh - 70px - 56px - 45px);
}

.viewport {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;

  @media only screen and (min-width: 1239.99px) {
    width: 1080px;
  }
}


.active-link {
  display: inline-block;
  position: relative;
}

.active-link:after {
  position: absolute;
  content: '';
  width: 90%;
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-right {
  margin-right: 1rem;
}

.margin-left {
  margin-left: 1rem;
}

.margin-top {
  margin-top: 1rem;
}

.flex-end {
  justify-content: flex-end;
}

.no-margin {
  margin: 0 !important;
}

.no-padding{
  padding: 0 !important;
}

.no-decorate {
  a:link {
    text-decoration: none !important;
  }
}

.form-label{
  &.text-gt-lg {
    margin-bottom: 1em;
  }
  &.text-lg {
    margin-bottom: 1em;
  }
}

.text-center {
  text-align: center;
}

  .text-overflow-ellipsis{
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

// Form fields
.mat-form-field-outline {
  border-radius: $border-radius;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
  width: $border-radius !important;
  border-radius: 0 $border-radius $border-radius 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: $border-radius 0 0 $border-radius !important;
  width: $border-radius !important;
}

.mat-form-field-appearance-outline {
  border-radius: $border-radius;
  .mat-form-field-infix {
    padding: 0.5em 0 0.5em 0;
  }
  .mat-form-field-suffix {
    top: 0 !important;
  }
  &.xs {
    .mat-form-field-wrapper {
      margin: 0;
      padding-bottom: unset;
    }
    .mat-form-field-infix {
      padding: 0.215em 0;
    }
    .mat-form-field-empty.mat-form-field-label {
      margin-top: -1em;
    }
    .mat-form-field-label{
      text-overflow: clip;
    }
    .mat-form-field-suffix .mat-icon-button {
      height: unset !important;
      position: relative;
      top: 0.2rem !important;
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline {
      // remove outline on disabled elements
      color: transparent;
      width: unset;
    }
  }
}
.xs.text-md-2 > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-suffix{
    top: 0.2em !important;
}

// Dialog theme
.portal-dialog-background {
  background: rgba(0, 0, 0, 0.72);
}

.settings-dialog {
  .minus-icon {
    margin-left: 5px;
  }

  app-icon {
    cursor: pointer;
  }
}

.portal-dialog {
  &.small {
    min-height: unset;
    .mat-dialog-container {
      .mat-dialog-content {
        min-height: unset;
      }
    }
  }
  h1 {
    font-weight: 400;
  }

  .mat-dialog-container {
    overflow: visible;
    border-radius: 12px;

    .mat-dialog-content {
      padding: 0;
      max-height: inherit;
      min-height: 65vh;
    }

    .mat-tab-body-wrapper {
      .mat-tab-body {
        overflow: initial;
        margin:1rem;
      }
    }

    .mat-tab-header {
      margin-bottom: 15px;
      padding-left: 15px;
    }

    .mat-tab-label {
      font-family: 'Oswald';
      font-size: 24px;
      font-weight: 300;
    }

    .sub-tab-group {
      .mat-tab-label {
        font-size: 18px;
        max-width: 30px;
        height: 40px;
      }
    }

    .sub-tab-group-extra-wide {
      min-width: 95% !important;
      .mat-tab-label {
        font-size: 18px;
        min-width: 100px;
        height: 40px;
      }
    }
  }

  .xs-input {
    .mat-form-field-appearance-outline {

      .mat-form-field-infix {
        border-top:0;
        padding: 9px 0 4px 0;
      }
    }

    .mat-date-range-input-container {
      margin-left: 15px;
      position: relative;
      top: 2px;

      .mat-date-range-input-start-wrapper {
        top:1px;
      }
    }

    .mat-form-field-appearance-outline {
      margin: 2px 0;
    }
  }

  .portal-input {
    &.xs {
      .mat-form-field-infix {
        padding: 0.215em 0;
      }
    }
  }

  .mat-form-field-appearance-outline {
    margin: 4px 0;
  }

  .mat-dialog-actions {
    margin-bottom: 0;
    margin-top: 50px;

    .action-button {
      width: 225px;
    }

    .action-button-small {
      width: 115px;
    }
  }

  .form-label-bottom-space {
    .form-label-wrapper {
      margin-bottom: 10px;
    }
  }

  .crew-form-employment-dates .mat-checkbox-label {
    width: 215px;
    font-size: smaller;
  }

  @media (max-width: 699px) {
    .crew-form-employment-dates .mat-checkbox-label {
      width: 100px;
      font-size: 8px;
    }
  }

  .crew-form-employment-dates .mat-checkbox {
    display: inline-block;
    width: 100%;
    margin: 0.1rem 0;
  }
}

.portal-dialog-template {
  h3 {
    margin-bottom: 15px;
  }

  .portal-form {
    margin-top: 20px;
  }
}

.portal-tab {
  .mat-tab-label {
    height: 58px;
    width: 250px;
    justify-content: flex-start;
    padding-left:15px;
    border-bottom: 0;
  }

  .mat-tab-list {
    .mat-ink-bar {
      display: none;
    }
  }
}

.slim-dialog {
  .mat-dialog-container {
    padding: 0;
    border: 1px black solid;
    border-radius: 5px;
  }
}

// Table overrides
.mat-table {
  .cdk-header-cell {
    color: unset;
  }

  .mat-cell {
    align-self: flex-start;
  }
  .mat-cell, .mat-header-cell, .mat-footer-cell {
    padding: 0;
  }
  &.borderless {
    .mat-row, mat-header-row, mat-footer-row,
    th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
      border-bottom: 1px solid transparent;
    }
  }

  &.compact {
    .mat-row, mat-header-row, mat-footer-row {
      min-height: 28px;
    }
  }
  &.compact-2 {
    .mat-row, mat-header-row, mat-footer-row {
      min-height: 1.5em;
    }
  }
  &.header-margin {
    mat-header-row {
      padding-bottom: 0.1em;
      margin-bottom: 1em;
    }
  }
}

.mat-header-cell {
  &.th-right-align {
    text-align: right;
    justify-content: flex-end;
  }
}

.settlement-details {
  .mat-row {
    border: none;
  }

  .mat-header-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  .mat-footer-row {
    border-bottom : none;
    border-top: 1px solid rgba(0, 0, 0, 0.4);
    margin-top: 5px;
    padding-top: 5px;
  }
}

// Other stuff
.mat-display-1 {
  margin: 0 !important;
}

.element-list {
  .mat-icon-button {
    height: 30px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.inline-tab-group {
  .mat-tab-label {
    height: 25px;
  }
}

.text-container {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.break-line {
  white-space: pre-line;
}

.light-body-2 {
  .mat-body-2 {
    font-weight: 400;
  }
}

.aligned-right {
  text-align: right;
}

.mat-input-element.aligned-right {
  text-align: right;
}

.no-wrap{
  white-space: nowrap !important;
}

.icon-button-container {
  width: 38px;

  .mat-icon-button {
    width:18px;
    height: 28px;
  }

  .minus-icon {
    position: relative;
    top: 1px;
  }

  &.md-icon-container {
    position: relative;
    bottom: 2px;
  }
}

.mat-tooltip{
  white-space: pre-line;
}

.trip-view {
  .trip-view-header {
    margin-bottom: 10px;
  }

  .mat-form-field-appearance-outline.xs .mat-form-field-infix {
    position: relative;
    bottom: 3px;
    padding: 0;
  }

  .mat-icon-button {
      height: 20px;
      margin-top: -5px;
      width: 25px;
  }

  .minus-icon {
      position: relative;
      top: 1px;
  }

  .mat-checkbox-inner-container {
    width: 13px;
    height: 13px;
    margin-left: 2px;
    position: relative;
    bottom: 1px;
  }
}

.user-profile {
  h4 {
   margin-bottom: 0;
  }
}

.mat-select.right-aligned {
  text-align: right;

  .mat-select-value {
    display: inline;
  }

  .mat-select-arrow-wrapper {
    display: none;
  }
}

.datepicker {
  .mat-form-field-wrapper {
    max-width: 150px;
  }

  .mat-form-field-suffix {
    width: 35px;

    .mat-datepicker-toggle-default-icon {
        height: 15px;
    }
  }

  input.mat-input-element {
      position: relative;
      bottom: 5px;
  }
}

.mat-snack-bar-container {
  color: #ffffff !important;
  &.snackbar-error {
    background-color: #f23a2f !important;
  }

  &.snackbar-success {
    background-color: #43a446 !important;
  }
}

.button-small {
  border: 0;
  background-color: #feedb3;
  border-radius: 10px;
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.value-mat-form {
  width: 60px;
}

.custom-mat-table {
  input:disabled {
    overflow: hidden !important;
    width: 100% !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  h3 {
    width: 100%;
  }

  th, td {
    font-size: 16px;
  }

  .custom-select .mat-form-field-wrapper {
    display: flex;
    flex-direction: column;
  }

  .mat-table {
    width: 100%;
    table-layout: fixed;
  }

  .add-button {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  mat-form-field.wide {
    font-size: 17px;
    max-width: 280px;
    width: 280px;
  }

  .expandable-mat-table {

    // TODO RICHARD
    // This will reduce paddings on the sides, make this work for all mat tables
    //td:nth-child(1) {
    //  padding-left: 0px;
    //}
    //
    //th:nth-child(1) {
    //  padding-left: 0px;
    //}

    .mat-header-row {
      position: sticky !important;
      top: 0 !important; /* Stick the header row to the top of the container */
      z-index: 1; /* Ensure the header row stays above the other rows */
      height: 65px !important;
    }

    .mat-form-field-disabled {
      margin-left: -10px;
    }

    th, td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-bottom: 5px;
      padding-right: 5px;
      text-align: left;
    }

    .mat-header-row th {
      font-size: 17px;
      padding-top: 10px !important;
    }

    .detail-row {
      height: 0;
    }

    .element-row td {
      border-bottom-width: 0;
    }

    .element-detail {
      padding-left: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .button-container {
    .mat-icon-button {
      width: 24px;
      height: 34px;
    }

    .minus-icon {
      position: relative;
      top: 1px;
    }
  }

  .mat-column-buttons {
    padding-right: 1rem !important;
    text-align: right;
  }

  .date {
    .mat-form-field-wrapper {
      max-width: 140px;
    }

    .mat-form-field-suffix {
      width: 35px;
    }

    input.mat-input-element {
      position: relative;
    }
  }
}

.max-height-500 {
  max-height: 500px !important;
}

.max-height-435 {
  max-height: 435px !important;
}

.max-height-380 {
  max-height: 380px !important;
}

//
//.mat-tab-group {
//  .mat-tab-label {
//    font-size: 10px;
//    max-width: 40px;
//    height: 40px;
//  }
//}

.oswald-sans-serif {
  font-family: "Oswald", sans-serif !important;
  font-weight: 400 !important;
}

.oswald-sans-serif-normal {
  font-family: "Oswald", sans-serif !important;
  font-weight: 200 !important;
}


.slim-input {
  .mat-form-field-appearance-outline {

    .mat-form-field-infix {
      border-top: 0;
      padding: 8px 0 0 0;
    }
  }

  .mat-date-range-input-container {
    margin-left: 15px;
    position: relative;
    top: 2px;

    .mat-date-range-input-start-wrapper {
      top: 1px;
    }
  }

  .mat-form-field-appearance-outline {
    margin: 2px 0;
  }
}

.progress-container {
  width: 100%;
  height: 4px;
  background: #2c2c2c;
  overflow: hidden;
  position: fixed;
  top:0;
  left:0;
}

.progress-bar {
  background: #eee0af;
  width: 0;
  transition-timing-function: ease-out;
  transition: width .2s;
  height: 100vh;
}

@media (min-width: 960px) {
  .progress-container {
    background: #ccc;
  }

  .progress-bar {
    background: #1e4c58;
  }
}

.insight-sidebar .mat-drawer-inner-container {
  overflow: hidden !important;
}
