@use 'sass:map';
@use '@angular/material' as mat;
@use 'src/app/shared/shared' as shared;
@use 'src/app/shared-slim/shared-slim' as shared-slim;
@use 'src/app/modules/layout/layout.component' as layout;
@use 'colors' as colors;

@mixin theme($theme) {
  @include shared.theme($theme);
  @include shared-slim.theme($theme);
  @include layout.theme($theme);

  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark: map.get($theme, is-dark);
  $primary: map.get($theme, 'primary');
  $accent: map.get($theme, 'accent');

  a {color: mat.get-color-from-palette($foreground, text);}
  a:visited { color: mat.get-color-from-palette($foreground, text); }
  a:hover { color: mat.get-color-from-palette($foreground, text); }
  a:focus { color: mat.get-color-from-palette($foreground, text); }
  a:hover, a:active { color: mat.get-color-from-palette($foreground, text) }

  .mat-app-background, .mat-drawer {
    background: mat.get-color-from-palette($background, background);
  }

  .mat-dialog-container, .background {
    background: mat.get-color-from-palette($background, dialog);
  }
  .mat-drawer-container {
    background: transparent;
  }

  .nav {
    height: 56px;
    background-color: transparent !important;
    color: mat.get-color-from-palette($foreground, text) !important;

    .active-link {
      color: #ffffff;
    }
  }

  .outer-viewport {
    @if $is-dark {
      background-color: mat.get-color-from-palette($background, status-bar);
    }@else{
      background-color: rgba(mat.get-color-from-palette($primary, main), 0.5);
    }
  }

  // Tabs
  .mat-tab-label, .mat-tab-link {
    text-align: left;
    opacity: 1 !important;

    background-color: mat.get-color-from-palette($primary, lighter);
    color: mat.get-color-from-palette($primary, 'lighter-contrast') !important; // To override <a> colors
    border: if($is-dark, 1px solid rgba(255, 255, 255, 0.12), 1px solid #A6A6A6);
    border-radius: 20px 20px 0 0;

    &.mat-tab-label-active, &.active-link {
      background-color: mat.get-color-from-palette($primary, main) !important;
      color: mat.get-color-from-palette($primary, 'main-contrast') !important;
      opacity: 1.0;
    }

    &.mat-tab-label-active:after, &.active-link:after {
      border-bottom: unset !important;
    }
    &.mat-tab-disabled {
      color: mat.get-color-from-palette($foreground, disabled-text);
    }
  }

  .color-warn {
    color: mat.get-color-from-palette($theme);
  }

  .text-lighter {
    color: if($is-dark, rgba(colors.$light-primary-text, 0.8), #494949);
  }

  .divider {
    $dividerColor: if($is-dark, rgba(255, 255, 255, 0.12), rgba(0, 0, 0, 0.12));
    &.left{
      border-left: 1px solid $dividerColor;
    }
  }

  .elevation-top {
    box-shadow: 0 -2px 3px if($is-dark, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25));
  }
  .elevation-bottom {
    box-shadow: 0 2px 3px if($is-dark, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25));
  }

  .mat-dialog-container {
    @if $is-dark {
      background-color: mat.get-color-from-palette($background, app-bar);
    }
  }

  .active-link:after {
    border-bottom: 1px solid #ffffff !important;
  }

  .mat-nav-list {
    .active-link:after {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, text) !important;
    }
  }

  .chat-message-text-container {
    box-shadow: 2px 2px 2px 1px rgba(mat.get-color-from-palette($foreground, text), 0.1);
  }
  .chat-message-other {
    background-color: mat.get-color-from-palette($primary, lighter);
    color: mat.get-color-from-palette($primary, 'lighter-contrast');
  }
  .chat-message-self {
    background-color: mat.get-color-from-palette($primary, main);
    color: mat.get-color-from-palette($primary, 'main-contrast');
  }

  .cirle {
    border: 1px solid mat.get-color-from-palette($foreground, text);
    border-radius: 25px;
  }

  .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: unset; // Will normally be a bottom line with the primary color palette
  }

  // Table
  .mat-table {
    background-color: unset;
    @if $is-dark {
      background: transparent;
    }

    .align-right {
      justify-content: end;
    }

    &.header-border {
      mat-header-row {
        @if $is-dark {
          border-bottom: 1px solid mat.get-color-from-palette($foreground, divider) !important;
        } @else {
          border-bottom: 1px solid rgba(0, 0, 0, 0.4) !important;
        }

      }
    }
  }

  // Forms
  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      background-color: colors.$gray;
    }

    &.mat-form-field-disabled {
      .mat-form-field-outline {
        // TODO: check where this came from
        background-color: unset !important;
        color: mat.get-color-from-palette($background, card);
        border: none;
      }
    }

  }

  // Checkbox theme
  .mat-checkbox-frame {
    border-color: mat.get-color-from-palette($foreground, text);
  }

  .mat-checkbox-checkmark-path {
    stroke: mat.get-color-from-palette($foreground, text) !important;
    stroke-width: 4 !important;
  }

  .mat-checkbox-indeterminate, .mat-checkbox-checked {
    &.mat-accent .mat-checkbox-background {
      background-color: mat.get-color-from-palette($background, background) !important;
      border: 2px solid mat.get-color-from-palette($foreground, text) !important;
    }
  }

  // Removes transparency from disabled elements
  .not-transparent {
    .mat-select-disabled .mat-select-value, .mat-input-element:disabled {
      color: mat.get-color-from-palette($foreground, text);
    }

    .mat-button-disabled {
      visibility: hidden;
    }
  }

  .mat-select-arrow {
    visibility: hidden;
  }

  .portal-dialog {
    .mat-form-field-outline {
      @if $is-dark {
        border: 1px solid rgba(mat.get-color-from-palette($foreground, text), 0.5);
      } @else {
        background-color: mat.get-color-from-palette($background, card);
        //border: 1px solid rgba(0, 0, 0, 0.5);
      }
    }

    .mat-form-field-appearance-outline{

      &.mat-form-field-disabled {
        .mat-form-field-outline {
          // remove outline on disabled elements
          background-color: colors.$gray;
          color: transparent;
        }
      }
    }

    .mat-dialog-container {
      .mat-tab-header {
        @if $is-dark {
          border-bottom: 1px solid white;
        }@else{
          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .portal-dialog-template {
    h3 {
      @if $is-dark {
        color: white;
      }@else{
        color: black;
      }
    }

    .mat-divider {
      @if $is-dark {
        border-top-color: mat.get-color-from-palette($foreground, divider);
      } @else{
        border-top-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .bright-on-dark-theme {
    $bright-on-dark-background: #FFF9E6;
    $bright-on-dark-background-secondary: #EEE0AF;
    $bright-on-dark-black: #2E2E39;
    $bright-on-dark-yellow: #FDC300;
    $chat-message-black: #494949;

    &.main-header {
      color: $bright-on-dark-background-secondary;

      .mat-icon {
        font-size: 25px;
        color: $bright-on-dark-background-secondary;
        line-height: 40px;
      }
    }

    .app-card {
      background-color: $bright-on-dark-background-secondary;
      color: $bright-on-dark-black;

      &.app-info-card {
        background-color: $bright-on-dark-background;
        color: #000000;
      }

      .app-icon {
        color: #000000;
      }

      a {
        color: #000000;
        text-decoration: none;
      }

      a:hover {
        color: #000000;
        text-decoration: underline;
      }
    }

    .chat-header {
      background-color: $bright-on-dark-background-secondary;
      color: $bright-on-dark-black;
      border-bottom: 1px solid $bright-on-dark-black;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .mat-icon {
        color: $bright-on-dark-yellow;
        margin-bottom: 16px;
      }
    }

    .mat-list-item-content {
      background-color: $bright-on-dark-background;
      color: $bright-on-dark-black;
      .mat-line {
        color: $bright-on-dark-black;
      }

      .mat-divider {
        border-top-color: #00000040;
      }
    }

    .output-container {
      border-radius: 10px;
      background-color: $bright-on-dark-background-secondary;
    }

    .scroll-container {
      background-color: $bright-on-dark-background-secondary;
      border-radius: 10px;
    }

    .mat-nav-list {
      padding-top: 0;
    }

    .timeline-container {
      color: #000000;
      fill: #000000;
    }

    .chat-section {
      background-color: $bright-on-dark-background;
    }

    .chat-message-info {
      color: $chat-message-black;
    }

    .chat-message-other {
      background-color: #8C8C91;
      color: #FFFFFF;
    }

    .chat-message-self {
      background-color: #EBEBEC;
      color: $chat-message-black;
    }

    .chat-input {
      background-color: $bright-on-dark-background-secondary;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .mat-icon {
        color: $bright-on-dark-black;
      }

      .mat-form-field-outline {
        background-color: #F9F9F9;
      }

      .mat-input-element, .mat-form-field-label {
        color: $chat-message-black;
      }

      .divider.left {
        border-left: 1px solid #00000040;
      }
    }
  }
}

.ql-font-oswald {
  font-family: "Oswald", sans-serif;
}

.ql-editor {
  //font-family: "Oswald", sans-serif; // Use Oswald font as standard
  font-size: 16px;
}
