@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $primary: map.get($config, primary);
  $is-dark: map.get($config, is-dark);
}

$nav-height: 56px;
$toolbar-height: 70px;
$footer-height: 45px;
$content-width: 1260px;
$viewport-width: 1080px;
$content-width-breakpoint: 1300px;
:host {
  display: inline-block;
  height: 100%;
  width: 100%;
}

mat-sidenav {
  width: 300px;
  min-width: 10vw;
  height: calc(100vh - $nav-height);

  @media only screen and (max-width: 959.99px) {
    width: 100%;
  }
}

.main-content {
  min-height: calc(100vh - $toolbar-height - $footer-height - 1px);
  margin: auto;

  @media only screen and (min-width: $content-width-breakpoint) {
    width: $content-width;
  }
}

.footer {
  max-height: $footer-height;
  height: 100%;
  @media only screen and (min-width: $content-width-breakpoint) {
    width: $content-width;
  }
  margin: auto;

  div {
    width: 90%;
    margin: auto;
  }
}

.nav-logo {
  max-height: 32px;
  cursor: pointer;
}

.login-logo {
  max-height: 100px;
}

.mat-toolbar {
  &.mat-toolbar-single-row {
    width: 85%;
    background-color: #E5E5E5;
    margin: auto;
    height: $toolbar-height;

    @media only screen and (min-width: $content-width-breakpoint) {
      width: $content-width;
    }

    @media only screen and (max-width: 959.99px) {
      width: 100%;
    }
  }
}
