@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .button {
    background: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, text);
  }
}
