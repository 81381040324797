@use 'sass:map';
@use '@angular/material' as mat;
@use './button/button.component' as button;
@use './icon-text-button/icon-text-button.component' as icon-text-button;

@mixin theme($theme) {
  @include button.theme($theme);
  @include icon-text-button.theme($theme);

  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $accent: map.get($config, accent);
  $primary: map.get($config, primary);

  .mat-raised-button {
    //border: 1px solid rgba(mat.get-color-from-palette($foreground, text), 0.5) !important;

    &.mat-button-disabled {
      color: rgba(mat.get-color-from-palette($foreground, text), 0.26) !important;
    }
  }
}

// Button theme
.mat-raised-button {
  border-radius: 10px !important;
  padding: 2px 40px !important;
  font-size: 18px !important;

  .mat-button-wrapper {
    span {
      width:100%;
    }
  }
}

.button-small {
  .mat-raised-button{
    height: 26px;
    font: 400 12px / 17.78px 'Oswald', sans-serif !important;
  }

  app-icon, .mat-icon {
    font-size: 12px !important;
  }
}

.button-compact {
  .mat-raised-button{
    padding: 2px 20px !important;

    app-icon {
      right: 1em;
    }
  }
}

.button-create {
  .mat-raised-button {
    font-size: 14px !important;
  }
}
